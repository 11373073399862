import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import CircularProgressButton from './common/CircularProgressButton';
import {isEmptyObject} from '../util/helpers';
import routes from '../util/routes';

// noinspection FunctionNamingConventionJS
function SystemInfoComponent(props) {
    const history = useHistory();
    const [openSystemInfoDialog, setOpenSystemInfoDialog] = useState(false);
    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    useEffect(() => {
        // noinspection ChainedFunctionCallJS
        let systemHasErrors = Object.keys(systemInfo).some(key => !systemInfo[key]);
        setOpenSystemInfoDialog(systemHasErrors);

    }, [systemInfo]);

    const handleCloudConnection = function handleCloudConnectionSystemError() {
        setOpenSystemInfoDialog(false);
        history.push(`${routes.settings.path}`);
    };

    return (
        <Dialog open={openSystemInfoDialog} onClose={() => setOpenSystemInfoDialog(false)}>

            <DialogTitle>Action Required!</DialogTitle>

            {!isEmptyObject(systemInfo) && !systemInfo.isDefaultCloudConnectionSet &&
            <DialogContent>
                <DialogContentText>
                    Please create a cloud connection.
                    <CircularProgressButton onClick={handleCloudConnection} buttonTextTransform='none'
                                            label='Go to Settings'/>
                </DialogContentText>
            </DialogContent>
            }
        </Dialog>
    );
}

SystemInfoComponent.propTypes = {};

SystemInfoComponent.defaultProps = {};

export default SystemInfoComponent;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Box, Button, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {useTheme} from '@material-ui/core/styles';
import {faFolder as solidFolderIcon} from '@fortawesome/free-solid-svg-icons';
import PageSection from '../common/PageSection';
import FormControlInput from '../common/FormControlInput';
import FormControlRadioGroup from '../common/FormControlRadioGroup';
import CircularProgressButton from '../common/CircularProgressButton';
import AdvancedSectionExpansion from '../common/AdvancedSectionExpansion';
import HomeDirectoryModal from './homeDirectoryModal/HomeDirectoryModal';
import SSHKeyTable from './formFields/SSHKeyTable';
import PasswordSelector from './formFields/PasswordSelector';
import IpAllowListTable from './formFields/IpAllowListTable';
import {StyledRowIcon} from '../folder/FolderListComponent';
import {getFolderPermissionString, isEmpty, isEmptyObject} from '../../util/helpers';

const StyledSaveButton = styled(Button)`
  margin-right: ${props => props.theme.spacing(1)}px;
`;

// noinspection FunctionNamingConventionJS
function UserCreateForm(props) {
    const theme = useTheme();
    const [showHomeDirectoryModal, setShowHomeDirectoryModal] = useState(false);

    return (
        <form onSubmit={props.handleSubmit}>
            <Grid container justifyContent='center'>
                <Grid container item md={8}>
                    <FormControlInput value={props.username} width='50' label='Username' name='username'
                                      placeholder='Enter unique username' onChange={props.setUsername}
                                      errorMessage={props.errors.username}
                    />
                </Grid>
                <Grid container item md={8}>
                    <FormControlInput value={props.notes} width='100' label='User Notes' name='notes'
                                      multiline onChange={props.setNotes} inputProps={{maxLength: 1000}} showCount
                                      placeholder='Enter notes about the user (optional)' onChangeReturnEvent
                                      errorMessage={props.errors.notes}
                    />
                </Grid>


                <Grid container item md={8}>
                    <PageSection title='Home Directory' titleVariant='body1' pb={0} mt={4}
                                 subtitle='Root location where user will land when connecting via SFTP.'
                                 subtitleVariant='body2'/>
                    <Dialog open={showHomeDirectoryModal} onClose={() => setShowHomeDirectoryModal(false)}>
                        <HomeDirectoryModal handleCloseModal={() => setShowHomeDirectoryModal(false)}
                                            save={(event, data) => props.setHomeFolderAndPermission(data)}
                                            currentHomeFolder={props.homeFolder.id}
                                            currentHomeFolderPermission={props.homeFolderPermission}/>
                    </Dialog>
                    <FormControlRadioGroup name='homeDirectory' value={props.homeDirectory}
                                           onChange={props.setHomeDirectory}
                                           options={[{
                                               value: '1', label: 'Create username home directory'
                                           }, {
                                               value: '2',
                                               label: <Grid container direction='row' style={{marginTop: '4px'}}
                                                            alignItems='center'>
                                                   <Grid item>
                                                       <Box mr={4}><Typography display='inline' variant='body2'>
                                                           Select home directory</Typography>
                                                       </Box>
                                                   </Grid>
                                                   <Grid item>
                                                       <CircularProgressButton size='small' variant='outlined'
                                                                               color='primary'
                                                                               onClick={() => setShowHomeDirectoryModal(true)}
                                                                               label='Select Folder'
                                                                               disabled={props.homeDirectory === '1'}
                                                                               disableElevation
                                                                               buttonTextTransform='none' mt={0}/>
                                                   </Grid>
                                               </Grid>,
                                               style: {alignItems: 'flex-start'}
                                           }]}
                    />
                    {!isEmpty(props.errors.name) &&
                    <Box ml={1} mt={1} mb={1}>
                        <Typography variant='caption' color='error'>{props.errors.name}</Typography>
                    </Box>
                    }
                    {props.homeDirectory === '2' && !isEmptyObject(props.homeFolder) &&
                    <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap'
                          style={{padding: '.875rem 1rem', backgroundColor: theme.palette.action.hover}}>
                        <Grid item style={{flex: '1 1 auto'}}>
                            <Box mr={4}>
                                <Typography variant='body2' style={{wordBreak: 'break-all'}}>
                                    <StyledRowIcon size='lg' icon={solidFolderIcon} color={theme.palette.primary.main}/>
                                    {props.homeFolder?.absolutePath}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item style={{flex: '0 0 auto'}}>
                            <Typography
                                variant='body2'>{getFolderPermissionString(props.homeFolderPermission)}</Typography>
                        </Grid>
                    </Grid>
                    }
                </Grid>

                <Grid container item md={8}>
                    <SSHKeyTable publicKeys={props.publicKeys} addPublicKey={props.addPublicKey}
                                 removePublicKey={props.removePublicKey} updatePublicKey={props.updatePublicKey}
                                 creatingUser errorMessage={props.errors["publicKeys[]"]}/>
                </Grid>

                <Grid container item md={8}>
                    <AdvancedSectionExpansion mt={3}>
                        <Grid item>
                            <PasswordSelector password={props.password} handleChange={props.setPassword}
                                              errors={props.errors} creatingUser/>
                        </Grid>

                        <Grid item>
                            <IpAllowListTable allowedIps={props.allowedIps} addIpAddress={props.addIpAddress}
                                              removeIpAddress={props.removeIpAddress} creatingUser
                                              errorMessage={props.errors["allowedIps[]"]}/>
                        </Grid>

                    </AdvancedSectionExpansion>
                </Grid>
                <Grid container item justifyContent='flex-end' md={8} style={{marginTop: '16px'}}>
                    <StyledSaveButton theme={theme} disableElevation type='submit'
                                      color='primary' variant='contained' size='small'>
                        Save
                    </StyledSaveButton>
                    <Button onClick={props.handleCancel} disableElevation size='small'>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

UserCreateForm.propTypes = {
    username: PropTypes.string,
    notes: PropTypes.string,
    homeDirectory: PropTypes.string,
    publicKeys: PropTypes.array,
    password: PropTypes.string,
    ipAddresses: PropTypes.array,
    setUsername: PropTypes.func,
    setNotes: PropTypes.func,
    setHomeDirectory: PropTypes.func,
    addPublicKey: PropTypes.func,
    removePublicKey: PropTypes.func,
    updatePublicKey: PropTypes.func,
    setPassword: PropTypes.func,
    addIpAddress: PropTypes.func,
    removeIpAddress: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    deleteUser: PropTypes.func,
    homeFolder: PropTypes.object,
    setHomeFolderAndPermission: PropTypes.func,
};

UserCreateForm.defaultProps = {};

export default UserCreateForm;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import PageSection from '../common/PageSection';
import InlineEditTextField from '../common/InlineEditTextField';
import FormControlRadioGroup from '../common/FormControlRadioGroup';
import SuccessMessageComponent from '../common/SuccessMessageComponent';
import TestConnectionResultTable from './TestConnectionResultTable';
import {isEmpty} from '../../util/helpers';

// noinspection FunctionNamingConventionJS
function AzureCloudConnectionEditForm(props) {

  const [errors, setErrors] = useState(props.errors);
  const [localInstanceCredentials, setLocalInstanceCredentials] = useState({accountName: '', connectionString: ''});
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  const updateInstanceCredentialsType = async function updateInstanceCredentialsType(event) {
    props.setInstanceCredentials(event);
    await props.handleSubmit(event, {useInstanceCredentials: event.target.value});
    setShowSavedMessage(true);
  };

  const handleInstanceCredentials = async function handleInstanceCredentials(event, data) {
    event.preventDefault();
    setLocalInstanceCredentials({...localInstanceCredentials, ...data});
    await props.handleSubmit(event, {...localInstanceCredentials, ...data});
    await props.handleSubmit(event, {useInstanceCredentials: false});
  };

  //noinspection MagicNumberJS, ConditionalExpressionJS
  return (<form>
    <Grid container justifyContent='center'>
      <Grid item md={8}>
        <InlineEditTextField name='name' label='Connection Name' labelVariant='body1' value={props.name}
                             save={(event, data) => props.handleSubmit(event, data)}
                             helperText='Name used to identify this cloud connection.'
                             placeholder='Enter unique name' required
                             updateMessage='Saved' errorMessage={errors.name}/>
      </Grid>
      <Grid item md={8}>
        <InlineEditTextField name='notes' label='Cloud Connection Notes' labelVariant='body1' value={props.notes}
                             save={(event, data) => props.handleSubmit(event, data)}
                             placeholder='Enter notes about the cloud connection (optional)'
                             multiline showCount inputProps={{maxLength: 1000}}
                             updateMessage='Saved' errorMessage={errors.notes}/>
      </Grid>
      <Grid item md={8}>
        <InlineEditTextField name='basePrefix' label='Container Resource URI' labelVariant='body1'
                             value={props.basePrefix}
                             save={(event, data) => props.handleSubmit(event, data)} required
                             placeholder='Enter URI for Azure Container'
                             helperText='Ex: https://storage-account-name.blob.core.windows.net/container-name'
                             errorMessage={errors.basePrefix} updateMessage='Saved' copyField={true}
        />
      </Grid>
      <Grid item md={8}>
        <InlineEditTextField name='region' label='Region' labelVariant='body1' value={props.region}
                             save={(event, data) => props.handleSubmit(event, data)}
                             placeholder='Enter storage account region'
                             helperText="Ex: Central US"
                             errorMessage={errors.region} updateMessage='Saved'/>
      </Grid>
      <Grid container item md={8}>
        <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                     subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'
                     action={<Grid container justifyContent='center'>
                       <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                     </Grid>}
        />
        <FormControlRadioGroup name='useInstanceCredentials' value={props.useInstanceCredentials}
                               onChange={updateInstanceCredentialsType}
                               options={[{
                                 value: true, label: 'Use instance profile credentials'
                               }, {
                                 value: false, label: 'Use unique credentials'
                               }]}
        />
      </Grid>
      {!props.useInstanceCredentials &&
      <Grid container item md={8}>
        <div style={{width: 'auto', paddingLeft: '28px', marginTop: '-16px'}}>
          <InlineEditTextField name='accountName' label='Storage Account Name' value={props.accountName}
                               required={!props.useInstanceCredentials}
                               save={(event, data) => handleInstanceCredentials(event, data)}
                               helperText='This is the default Azure ID used for this cloud connection.'
                               placeholder='Enter storage account name' updateMessage='Saved'
                               errorMessage={errors.accountName}/>
          <InlineEditTextField name='connectionString' label='Connection String'
                               required={!props.useInstanceCredentials && !props.connectionStringSet}
                               emptyText={`${props.connectionStringSet ? '**********' : 'None'}`}
                               value={props.connectionString} type='password' updateMessage='Saved'
                               save={(event, data) => handleInstanceCredentials(event, data)}
                               placeholder={`${props.connectionStringSet ? '**********' : 'Enter connection string'}`}
                               helperText='This is the default connection string used for this cloud connection.'
                               errorMessage={errors.connectionString}/>
        </div>
      </Grid>
      }
      {!isEmpty(props.connectivity) &&
      <Grid container item md={8}>
        <TestConnectionResultTable connectivity={props.connectivity} connectionId={props.id}/></Grid>
      }
    </Grid>
  </form>);
}

AzureCloudConnectionEditForm.propTypes = {
  name: PropTypes.string,
  notes: PropTypes.string,
  basePrefix: PropTypes.string,
  accountName: PropTypes.string,
  setAccountName: PropTypes.func,
  connectionString: PropTypes.string,
  connectionStringSet: PropTypes.bool,
  region: PropTypes.string,
  useInstanceCredentials: PropTypes.bool,
  setInstanceCredentials: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  errors: PropTypes.object,
  connectivity: PropTypes.object,
};

AzureCloudConnectionEditForm.defaultProps = {
  accessSecretSet: false,
};

export default AzureCloudConnectionEditForm;

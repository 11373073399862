import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {InputAdornment, makeStyles, useTheme} from '@material-ui/core';
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import {isEmpty} from '../../util/helpers';
import httpStatus from '../../util/http_status';
import {convertErrorsToObject, handleErrors} from '../../util/errorHandler';
import CustomAlert from './CustomAlert';
import FormControlInput from './FormControlInput';
import SuccessMessageComponent from './SuccessMessageComponent';
import {authenticationService} from '../../container/auth/authenticationService';
import {setAuthenticated} from '../../action';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy as CopyIcon} from "@fortawesome/free-regular-svg-icons";
import styled from 'styled-components';

const StyledIcon = styled(FontAwesomeIcon)`
    height: 25px;
    width: 25px;
`;

// noinspection MagicNumberJS
const useStyles = makeStyles((theme) => ({
    editRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    },
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    emptyText: {
        color: theme.palette.action.active,
    },
    iconColor: {
        color: theme.palette.primary.main,
    }
}));

// noinspection FunctionNamingConventionJS
function InlineEditTextField({
                                 value, save, name, updateMessage, emptyText, placeholder,
                                 label, labelVariant,
                                 multiline, inputProps, showCount, width, helperText, prefix, style, type, displayValue,
                                 errorMessage, required, copyField, secure
                             }) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [editing, setEditing] = useState(!isEmpty(errorMessage));
    const [valueToEdit, setValueToEdit] = useState(value);
    const [errors, setErrors] = useState({});
    const [oldValue, setOldValue] = useState(value);
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [showSecure, setShowSecure] = useState((secure) ? false : true);
    const toggleShowSecure = (event) => {
        event.stopPropagation();
        setShowSecure(!showSecure);
    };
    useEffect(() => {
        if (!isEmpty(errorMessage)) {
            setEditing(true);
        }
    }, [errorMessage]);

    const submit = async function inlineEditingSubmit(event) {
        // Check if the field is required
        if (required && isEmpty(valueToEdit)) {
            setErrors({[name]: 'This field is required.'})
        } else if (oldValue === valueToEdit) { // Do not update if the value didn't change
            setEditing(false);
            setErrors({});
        } else { // If the value did change, call the api.
            try {
                let data = {};
                data[name] = valueToEdit;
                await save(event, data);
                setEditing(false);
                setOldValue(valueToEdit);
                setShowSavedMessage(true);
                setErrors({});
            } catch (error) {
                if (isEmpty(error.response)) {
                    toast.notify(({onClose}) => <CustomAlert type='error'
                                                             message={'Something went wrong. Update failed.'}
                                                             onClose={onClose}/>);
                    setErrors(error);
                } else if (error.response.status === httpStatus.badRequest) {
                    let tempErrors = convertErrorsToObject(error.response);
                    setErrors(tempErrors);
                } else if (error.response.status === httpStatus.conflict) {
                    let error = {};
                    error[name] = 'Duplicate entry.';
                    setErrors(error);
                } else if (error.response.status === httpStatus.unAuthorized) {
                    const tempErrors = handleErrors(error.response);
                    toast.notify(({onClose}) =>
                        <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
                    authenticationService.clearTokenInfo();
                    dispatch(setAuthenticated(false));
                }
            }
        }
    };

    const onBlur = function inlineEditingOnBlur(event) {
        submit(event);
    };

    const handleKeyDown = function inlineEditingKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            submit(event);
        }
    };

    // Build display when in not editing state
    const display = function displayNonEditingValue() {
        let body = [];
        if (!isEmpty(prefix)) {
            body.push(<span key='1' style={{marginRight: '8px'}}>{prefix}</span>);
        }
        if (!isEmpty(displayValue)) {
            body.push(<span key='2'>{displayValue}</span>);
        } else if (!isEmpty(valueToEdit)) {
            body.push(<span key='3'>{valueToEdit}</span>);
        } else {
            body.push(<span key='4' className={classes.emptyText}>{emptyText}</span>);
        }
        return body;
    };

    const copyValue = (value) => {
        navigator.clipboard.writeText(value).then(() => {
            toast.notify(({onClose}) => <CustomAlert type='success' message={`${label} copied!`} onClose={onClose}/>);
        });
    };

    // noinspection FunctionWithMoreThanThreeNegationsJS
    return (<div style={{display: 'flex', flexDirection: 'column', marginTop: '16px', width: '100%', ...style}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography gutterBottom variant={labelVariant}>{label}</Typography>
            <SuccessMessageComponent mb={1} ml={isEmpty(label) ? 0 : 2} message={updateMessage} show={showSavedMessage}
                                     setShow={setShowSavedMessage}/>
        </div>
        {editing &&
        <FormControlInput
            value={valueToEdit}
            autoFocus={isEmpty(errorMessage)}
            onBlur={onBlur}
            onKeyDown={handleKeyDown}
            onChange={e => setValueToEdit(e.target.value)}
            errorMessage={isEmpty(errors[name]) ? errorMessage : errors[name]}
            margin='none'
            placeholder={placeholder}
            multiline={multiline}
            inputProps={inputProps}
            showCount={showCount}
            width={width}
            helperText={helperText}
            prefix={prefix}
            type={(!isEmpty(type)) ?
                type :
                (showSecure) ?
                    'text' :
                    'password'
            }
            endAdornment={secure && <InputAdornment position='end'>
                <IconButton edge='end' onClick={toggleShowSecure}
                            aria-label='toggle password visibility'
                            size='small'
                >
                    {showSecure ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
            </InputAdornment>}
        />
        }
        {!editing &&
        <Typography variant='body2' onClick={() => setEditing(true)}>
            {(showSecure) ? display() : '**************************************'}
            <IconButton aria-label='Edit' size='small'
                        onClick={() => setEditing(true)} className={classes.iconMarginLeft}>
                <CreateSharpIcon fontSize='small' className={classes.iconColor}/>
            </IconButton>
            {copyField &&
            <IconButton size={"small"} style={{height: '25px', width: '25px'}} onClick={function handleCopyUrl(event) {
                event.stopPropagation()
                copyValue(valueToEdit)
            }}>
                <StyledIcon icon={CopyIcon} color={theme.palette.primary.main} size={"sm"}/>
            </IconButton>
            }
            {secure &&
            <IconButton size={"small"} style={{height: '25px', width: '25px'}} onClick={toggleShowSecure}>
                {(showSecure) ?
                    <Visibility/> :
                    <VisibilityOff/>}
            </IconButton>
            }
        </Typography>
        }
    </div>);
}

InlineEditTextField.propTypes = {
    value: PropTypes.any,
    save: PropTypes.func.isRequired,
    name: PropTypes.string,
    updateMessage: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    labelVariant: PropTypes.string,
    emptyText: PropTypes.string,
    helperText: PropTypes.string,
    prefix: PropTypes.string,
    multiline: PropTypes.bool,
    inputProps: PropTypes.any,
    style: PropTypes.object,
    type: PropTypes.string,
    displayValue: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool,
    copyField: PropTypes.bool,
    secure: PropTypes.bool,
};

InlineEditTextField.defaultProps = {
    emptyText: 'None',
    labelVariant: 'caption',
    updateMessage: 'Saved',
    displayValue: null,
    errorMessage: null,
    required: false,
    copyField: false,
    secure: false,
};

export default InlineEditTextField;

import React, {useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import {Card, CardContent, makeStyles, useTheme} from '@material-ui/core';
import routes from '../../util/routes';
import {convertErrorsToObject} from '../../util/errorHandler';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../common/styled';
import AwsCloudConnectionForm from './AwsCloudConnectionForm';
import AzureCloudConnectionForm from "./AzureCloudConnectionForm";
import {isEmpty} from "../../util/helpers";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}));

const initCloudConnection = (provider) => {
    return {
        name: '',
        notes: '',
        basePrefix: '',
        region: '',
        ...(provider === 'aws') && {
            cloudProvider: 'aws',
            encryptionType: 'SSE_S3',
            encryptionId: '',
            useInstanceCredentials: true,
            accessKeyId: '',
            accessSecret: '',
        },
        ...(provider === 'azure') && {
            cloudProvider: 'azure',
            useInstanceCredentials: false,
            accountName: '',
            connectionString: '',
        }
    }
};

// noinspection FunctionNamingConventionJS
function CloudConnectionCreateComponent(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const location = useLocation();
    const provider = (!isEmpty(new URLSearchParams(location.search).get('provider')))
        ? new URLSearchParams(location.search).get('provider')
        : 'aws';
    const providerTitle = (provider === 'aws') ? 'AWS' : 'Azure'


    const [isBlocking, setIsBlocking] = useState(false);
    const [cloudConnection, setCloudConnection] = useState(initCloudConnection(provider));
    const [errors, setErrors] = useState(initCloudConnection);

    const resetErrors = function resetCloudConnectionErrors() {
        setErrors(initCloudConnection);
    };

    const handleTextChange = function handleTextChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value});
    };

    const handleInstanceCredentialsChange = function handleInstanceCredentialsChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value === "true"});
    };

    const onSubmit = async function createCloudConnection(event) {
        resetErrors();
        event.preventDefault();
        try {
            await props.saveCloudConnection(cloudConnection);
            toast.notify(({onClose}) => 
                <CustomAlert message={`Created cloud connection ${cloudConnection.name}.`} onClose={onClose}/>);
            history.push(routes.settings.path);
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors({...tempErrors});
        }
    };

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.settings.path);
            }
        } else {
            history.push(routes.settings.path);
        }
    };

    // noinspection MagicNumberJS
    return (
        <div className={classes.root}>
            <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.settings.path)}>
                    <StyledBreadcrumbIcon theme={theme} />
                    Back to settings
                </StyledBreadcrumbLink>
            </StyledBreadcrumbs>
            <Card variant='outlined'>
                <CustomCardHeader
                    title={routes.createCloudConnection.pageTitle(providerTitle)}
                    titleAlign='center'
                />
                <CardContent>
                    {provider === 'aws' &&
                    <AwsCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                            name={cloudConnection.name} setName={handleTextChange}
                                            notes={cloudConnection.notes} setNotes={handleTextChange}
                                            basePrefix={cloudConnection.basePrefix}
                                            setBasePrefix={handleTextChange}
                                            encryptionType={cloudConnection.encryptionType}
                                            setEncryptionType={handleTextChange}
                                            encryptionId={cloudConnection.encryptionId}
                                            setEncryptionId={handleTextChange}
                                            region={cloudConnection.region} setRegion={handleTextChange}
                                            useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                            setInstanceCredentials={handleInstanceCredentialsChange}
                                            keyId={cloudConnection.accessKeyId}
                                            setKeyId={handleTextChange}
                                            keySecret={cloudConnection.accessSecret}
                                            setKeySecret={handleTextChange}
                                            errors={errors}
                    />
                    }
                    {provider === 'azure' &&
                    <AzureCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                              name={cloudConnection.name} setName={handleTextChange}
                                              notes={cloudConnection.notes} setNotes={handleTextChange}
                                              basePrefix={cloudConnection.basePrefix}
                                              setBasePrefix={handleTextChange}
                                              region={cloudConnection.region} setRegion={handleTextChange}
                                              useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                              setInstanceCredentials={handleInstanceCredentialsChange}
                                              accountName={cloudConnection.accountName}
                                              setAccountName={handleTextChange}
                                              connectionString={cloudConnection.connectionString}
                                              setConnectionString={handleTextChange}
                                              errors={errors}
                    />
                    }
                </CardContent>
            </Card>
        </div>
    );
}

CloudConnectionCreateComponent.propTypes = {
    saveCloudConnection: PropTypes.func,
};

export default CloudConnectionCreateComponent;
